/* You can add global styles to this file, and also import other style files */
@import './assets/scss/common/variables';

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-blue;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #788fbb; //#3f51b5;
}

.mdc-form-field>label {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}

.mat-mdc-snack-bar-container {
    &.ef-danger-snackbar {
        --mdc-snackbar-container-color: #dc3545;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }

    &.ef-success-snackbar {
        --mdc-snackbar-container-color: #28a745;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

.overflow-wrap-content {
    overflow-wrap: anywhere;
}